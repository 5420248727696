<template>
  <div class="el-input">
    <the-mask
      mask="##-#######"
      class="el-input__inner"
      type="tel"
      autocomplete="off"
      name="ein"
      :masked="true"
      :value="value"
      @input.native="updateInput($event)"
      ref="einNumber" />
  </div>
</template>
<script>
import { TheMask } from 'vue-the-mask';
export default {
  components: { TheMask },
  props: {
    value: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.autofocus ? this.$refs.einNumber.$el.focus() : '';
  },
  methods: {
    updateInput(event) {
      let value = event.target.value;
      if (value) this.$emit('input', value.replace(/\D+/g, ''));
      else this.$emit('input', null);
    }
  }
};
</script>
<style lang="scss" scoped></style>
